import React from "react";
import { css } from "@emotion/core";
import { PurpleLink } from "../shared/style";
import { Container } from "react-bootstrap";
const Footer = () => {
  return (
    <Container
      className="my-5"
      css={css`
        @media (min-width: 900px) {
          margin-left: 190px;
        }
        @media (min-width: 1200px) {
          margin-left: 350px;
        }
      `}
    >
      <span
        css={css`
          font-weight: 600;
        `}
      >
        Kyrsten Sanderson
      </span>{" "}
      <span
        css={css`
          margin-top: 20px;
          margin-left: 100px;
          @media (max-width: 768px) {
            display: block;
            margin-left: 0;
          }
        `}
      >
        <PurpleLink href="#" target="__blank" rel="noreferrer" fontWeight={600}>
          Instagram{" "}
        </PurpleLink>
        &nbsp;/&nbsp;
        <PurpleLink href="#" target="__blank" rel="noreferrer" fontWeight={600}>
          Email
        </PurpleLink>
        &nbsp;/&nbsp;
        <PurpleLink href="#" target="__blank" rel="noreferrer" fontWeight={600}>
          Twitter
        </PurpleLink>
        &nbsp;/&nbsp;
        <PurpleLink href="#" target="__blank" rel="noreferrer" fontWeight={600}>
          Resume
        </PurpleLink>
      </span>
    </Container>
  );
};

export default Footer;
