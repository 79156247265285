import React from "react";
import { Global, css } from "@emotion/core";
import Hero from "../components/Hero";
import Footer from "../components/Footer";
import Project from "../components/Project";
import BackToTopBtn from "../components/BackToTopBtn";
import placeholder from "../../static/assets/placeholder.jpg";

const Index = () => {
  return (
    <>
      <div>
        <Hero />
        <BackToTopBtn />
        <Project img={placeholder} />
        <Project img={placeholder} />
        <Project img={placeholder} />
        <Footer />
      </div>
      <Global
        styles={css`
          html {
            scroll-behavior: smooth;
          }
          body {
            font-family: var(--font-family);
            color: var(--font-color);
          }
          a {
            color: #4433ff;
            text-decoration: none;
            font-weight: 400;
            &:hover {
              text-decoration: none;
              color: #140fb1;
      
          }
          :root {
            @import url("https://fonts.googleapis.com/css?family=Karla&display=swap");
            // Website font
            --font-family: "Karla", sans-serif;
            --font-color: #191545;
            --font-purple: #4433ff;
          }
        `}
      ></Global>
    </>
  );
};

export default Index;
