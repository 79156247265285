import React from "react";
import { css } from "@emotion/core";
import { Container, Row, Col } from "react-bootstrap";
import { PurpleLink } from "../shared/style";
import { useStaticQuery, graphql } from "gatsby";
const Hero = () => {
  const data = useStaticQuery(graphql`
    {
      allFile(filter: { sourceInstanceName: { eq: "portfolio_owner" } }) {
        nodes {
          name
          childMarkdownRemark {
            id
            frontmatter {
              title
              socials {
                name
                link
              }
            }
            html
          }
        }
      }
    }
  `);
  const {
    title,
    socials,
  } = data.allFile.nodes[0].childMarkdownRemark.frontmatter;
  const { html } = data.allFile.nodes[0].childMarkdownRemark;
  const socialLinks = socials.map(link => {
    return (
      <>
        
        <PurpleLink
          href={link.link}
          target="__blank"
          rel="noreferrer"
          fontWeight={600}
        >
          {link.name}
        </PurpleLink>
        &nbsp;/&nbsp;
      </>
    );
  });
  return (
    <Container
      css={css`
        margin-top: 110px;
        @media (min-width: 900px) {
          margin-left: 190px;
        }
        @media (min-width: 1200px) {
          margin-left: 350px;
        }
      `}
    >
      <h1
        css={css`
          font-size: 110px;
          font-weight: 600;
          @media (max-width: 768px) {
            font-size: 52px;
          }
        `}
      >
        {title}.
      </h1>
      <Row>
        <Col>
          <p
            className="my-3"
            css={css`
              line-height: 32px;
              font-size: 1.3rem;
              font-size: 24px;
              width: 80%;
              @media (min-width: 768px) {
                width: 60%;
              }
            `}
            dangerouslySetInnerHTML={{ __html: html }}
          ></p>
          <div
            className="mt-4"
            css={css`
              font-size: 16px;
              font-weight: 400;
              @media (max-width: 768px) {
                font-size: 14px;
              }
            `}
          >
            {socialLinks}
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Hero;
