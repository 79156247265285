import React, { useState, useEffect } from "react";
import DownArrow from "./icons/DownArrow";
import { css } from "@emotion/core";
const BackToTopBtn = () => {
  const [isTop, setIsTop] = useState(true);

  useEffect(() => {
    const displayBackToTopBtn = () => {
      if (document !== undefined) {
        if (window.pageYOffset > 200) {
          setIsTop(false);
        } else {
          setIsTop(true);
        }
      }
    };
    window.addEventListener("scroll", displayBackToTopBtn);
    return () => window.removeEventListener("scroll", displayBackToTopBtn);
  });

  const backToTop = () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };
  return (
    <div
      onClick={backToTop}
      css={css`
          position: fixed;
          right: 40px;
          bottom: 50px;
          cursor: pointer;
          opacity: ${isTop ? 0 : 1};
          padding: .75rem .25rem;
          transition-timing-function: transition-timing-function: cubic-bezier(0, 0, 0.58, 1);
          z-index: 1;
          //#6954FF
        `}
    >
      <DownArrow />
    </div>
  );
};

export default BackToTopBtn;
