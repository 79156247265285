import React from "react";
import { css } from "@emotion/core";
const Button = ({ cta, url }) => {
  return (
    <a
      className="my-3"
      href={url}
      css={css`
        background-color: var(--font-color);
        color: #fff;
        padding: 10px 20px;
        border-radius: 4px;
        text-decoration: none;
        display: inline-block;
        transition-duration: 0.2s;
        transition-property: all;
        transition-timing-function: linear;
        font-weight: 600;
        &:hover {
          text-decoration: none;
          background-color: rgb(68, 51, 255);
          color: #fff;
        }
      `}
    >
      {cta}
    </a>
  );
};

export default Button;
