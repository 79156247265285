import React from "react";
import { css } from "@emotion/core";

const Image = ({ src, id, alt, height, width }) => {
  let ratio = 1;
  if (height && width) {
    ratio = height / width;
  }

  return (
    <div
      key={`image-${id}`}
      css={css`
        display: inline-block;
        overflow: hidden;
        width: 100%;
      `}
    >
      <img
        css={css`
          background-position: center;
          background-size: cover;
          display: inline-block;
          height: 0;
          width: 100%;
          background-image: url(${src});
          padding-top: ${ratio * 100}%;
        `}
        alt={alt}
      />
    </div>
  );
};
export default Image;