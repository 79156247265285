import React from "react";

const DownArrow = () => {
  return (
    <svg
      version="1.1"
      id="Ebene_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="53px"
      height="20px"
      viewBox="0 0 53 20"
      enableBackground="new 0 0 53 20"
    >
      <g id="Ebene_3"></g>
      <g>
        <polygon points="43.886,16.221 42.697,17.687 26.5,4.731 10.303,17.688 9.114,16.221 26.5,2.312 	"></polygon>
      </g>
    </svg>
  );
};

export default DownArrow;
