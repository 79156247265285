import styled from "@emotion/styled";

export const PurpleLink = styled.a`
  color: #4433ff;
  ${props =>
    props.fontWeight &&
    `
font-weight: ${props.fontWeight};
`}
  text-decoration: none;
  &:hover {
    text-decoration: none;
    color: #140fb1;
  }
`;
