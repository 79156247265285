import React from "react";
import { css } from "@emotion/core";
import { Container, Row, Col } from "react-bootstrap";
import Button from "./Button";
import Image from "./Image";

const Project = ({
  title = "title",
  content = "Eveniet rerum nisi minima quisquam consequuntur voluptas dolores pariatur natus? Expedita, tempora dolorem? Deserunt modi, accusantium temporibus enim quae quos neque dignissimos.",
  cta = "Learn More",
  img,
}) => {
  return (
    <Container
      css={css`
        margin-top: 275px;
        margin-bottom: 275px;
        @media (min-width: 900px) {
          margin-left: 190px;
        }
        @media (min-width: 1200px) {
          margin-left: 350px;
        }
      `}
    >
      <Row>
        <Col>
          <h2
            css={css`
              font-weight: 600;
              font-size: 32px;
            `}
          >
            {title}
          </h2>
          <p
            css={css`
              font-size: 16px;
            `}
          >
            {content}
          </p>
          <Button cta="Learn More" url="#" />
        </Col>
        <Col sm={12} lg={7}>
          <Image height={2} width={3} src={img} />
        </Col>
      </Row>
    </Container>
  );
};

export default Project;
